<template>
  <div class="Invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <table class="center">
          <tbody class="report-container">
            <v-card-text>
              <v-data-table
                dense
                readonly
                :sort-by="sortBy"
                :headers="headers"
                :itemsPerPage="Invoice.length"
                hide-default-footer
                :items="Invoice"
              >
                <template v-slot:item="{ item }">
                  <tr v-if="item.totalPrice - item.totalCost !== 0">
                    <td class="text-left">
                      <a href="#" @click="viewInvoice(item.id)">{{
                        item.localId
                      }}</a>
                    </td>
                    <td class="text-left text-capitalize">
                      {{ item.date | moment("MM/DD/YYYY") }}
                    </td>
                    <td class="text-left">
                      {{ item.Customer.name }}
                    </td>
                    <td class="text-right">
                      {{ item.total | currency }}
                    </td>
                    <td class="text-right">
                      {{ item.payment | currency }}
                    </td>
                    <td class="text-right">
                      {{ item.due | currency }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </tbody>
        </table>
      </v-card>
    </div>
  </div>
</template>

<script>
import invoiceService from "@/modules/Invoice/service.js";
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  props: [
    "SalesRepUserId",
    "CustomerId",
    "hideProfit",
    "hideCost",
    "start",
    "end",
  ],
  data() {
    return {
      isLoading: true,
      Invoice: [],
      sortBy: "name",
      headers: [
        {
          text: "INVOICE NUMBER",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "DATE",
          align: "left",
          value: "Invoice.date",
          sortable: true,
        },
        {
          text: "CUSTOMER",
          align: "center",
          value: "customer.name",
          sortable: true,
        },
        {
          text: "AMOUNT",
          align: "right",
          value: "total",
          sortable: true,
        },
        {
          text: "PAYMENT",
          align: "right",
          value: "total",
          sortable: true,
        },
        {
          text: "DUE",
          align: "right",
          value: "total",
          sortable: true,
        },
        // {
        //   text: "COST",
        //   align: "right",
        //   value: "totalCost",
        //   sortable: true,
        // },
        // {
        //   text: "PROFIT",
        //   align: "right",
        //   value: "profit",
        //   sortable: true,
        // },
        // {
        //   text: "USER",
        //   align: "left",
        //   value: "Invoice.User.name",
        //   sortable: true,
        // },
      ],
    };
  },
  async created() {
    await this.getInvoiceDetail();
  },
  methods: {
    viewInvoice(invoice) {
      const hashId = hashids.encode(invoice);
      let routeData = null;
      if (this.$route.meta.public) {
        routeData = this.$router.resolve({
          path: `/public/invoice/${hashId}/view`,
        });
      } else {
        routeData = this.$router.resolve({
          path: `/global/invoice/${hashId}/view`,
        });
      }

      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    color(item) {
      let value = "black";
      let profit = item.totalSale - item.totalCost;
      if (profit < 0) {
        value = "red";
      }
      return value;
    },
    getInvoiceDetail() {
      let where = {};
      if (this.start) where.start = this.start;
      if (this.end) where.end = this.end;
      if (this.CustomerId) where.CustomerId = this.CustomerId;
      if (this.SalesRepUserId) where.SalesRepUserId = this.SalesRepUserId;

      // console.log("SalesRepUserId", this.SalesRepUserId);
      // let where = {
      //   start: this.start,
      //   end: this.end
      // };

      // if (this.ProductId) {
      //   where.ProductId = this.ProductId
      // }

      return invoiceService.getAll(where).then((response) => {
        // console.log("response Inocie", response);
        this.$Progress.finish();
        this.Invoice = response.data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
